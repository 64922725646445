import BrandsPage from 'src/dispensary/brands';
import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';

import { getStaticPaths } from 'utils/ssr';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };
export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);

BrandsPage.layout = MainDispensaryLayout;
export default BrandsPage;
